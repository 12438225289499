"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStyles = void 0;
/**
 * Create white label styles based on the model setting configuration.
 * This function migrates the branded moment styles generation in ds__base.html to next_js app
 * @returns
 */

const createStyles = _ref => {
  let {
    primaryCtaColor,
    pbuPrimaryBrand,
    pbuBrandBackground
  } = _ref;
  const styles = [];
  const classMap = {};
  if (primaryCtaColor) {
    styles.push(`.primary-cta-color {background: ${primaryCtaColor};}.hover\\:primary-cta-color:hover {background: ${primaryCtaColor};filter: brightness(90%);}`);
    classMap.primaryCtaColor = "primary-cta-color";
    classMap.primaryCtaColorHover = "hover:primary-cta-color";
  }
  if (pbuPrimaryBrand) {
    styles.push(`.pbu-primary-brand {background: ${pbuPrimaryBrand};height: 8px;}`);
    //TODO: cleanup after the new NavBar feature fully rolled out
    styles.push(`.pbu-primary-brand-new {background: ${pbuPrimaryBrand};}`);
    classMap.pbuPrimaryBrand = "pbu-primary-brand";
  }
  if (pbuBrandBackground) {
    styles.push(`.pbu-brand-background {background: ${pbuBrandBackground};}`);
    classMap.pbuBrandBackground = "pbu-brand-background";
  }
  return {
    rawStyles: styles.join(""),
    classMap
  };
};
exports.createStyles = createStyles;